<template>
  <div class="messages-container">
    <v-snackbar :value="snackbar" @input="setSnackbar" :timeout="4000" top>
      {{ snackBartext }}
    </v-snackbar>
    <v-list-item v-if="selectedRoom" class="chat-details-header">
      <v-btn icon class="close-btn" @click="goBack">
        <v-icon>fa-chevron-left</v-icon>
      </v-btn>
      <v-list-item-avatar>
        <v-img
          v-if="getOtherUser(selectedRoom)['profile']['imgUrl']"
          :src="getOtherUser(selectedRoom)['profile']['imgUrl']"
        ></v-img>
        <v-avatar
          :color="
            colorJson &&
            getOtherUser(selectedRoom)['profile'] &&
            getOtherUser(selectedRoom)['profile']['name']
              ? colorJson[
                  getOtherUser(selectedRoom)
                    ['profile']['name'].substring(0, 1)
                    .toUpperCase()
                ] || '#f00'
              : '#ddd'
          "
          size="48"
          v-else
        >
          <span class="white--text text-h5">{{
            getOtherUser(selectedRoom)['profile']['name'].substring(0, 1)
          }}</span>
        </v-avatar>
      </v-list-item-avatar>

      <v-list-item-content class="ml-4">
        <v-list-item-title>{{
          getOtherUser(selectedRoom)['profile']['name']
        }}</v-list-item-title>
        <v-list-item-subtitle>
          <div
            class="font-weight-regular"
            style="color: green"
            v-if="
              selectedRoom['typing_user'] &&
              selectedRoom['typing_user'][
                `${getOtherUser(selectedRoom)['profile']['uid']}`
              ]
            "
          >
            typing....
          </div>
          <div
            class="font-weight-regular"
            v-else-if="
              getOtherUser(selectedRoom)['online'] &&
              selectedRoom[getMyUID]['readReceipts']
            "
          >
            online
          </div>
        </v-list-item-subtitle>
      </v-list-item-content>

      <v-text-field
        v-if="searchToggle"
        label="Solo"
        placeholder="search text"
        :value="findChatText"
        @input="setSearchText"
        solo
        clearable
        hide-details
        dense
        autofocus
      ></v-text-field>

      <v-btn icon class="mr-2" @click="setSearchToggle(!searchToggle)">
        <v-icon>fa-search</v-icon>
      </v-btn>

      <v-menu
        :value="chatDetailSettings"
        @input="setChatDetailSettings"
        :close-on-content-click="false"
        :max-width="300"
        offset-y
        absolute
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>fa-bars</v-icon>
          </v-btn>
        </template>

        <v-card :max-width="300">
          <v-list>
            <v-list-item
              one-line
              v-if="selectedRoom[getMyUID]['isBlocked'] == false"
              @click="setIsBlockDialog(true)"
            >
              <v-list-item-avatar class="ml-0 mr-0">
                <v-icon small>fa-ban</v-icon>
              </v-list-item-avatar>
              <v-list-item-content class="ml-2 mr-0">
                <v-list-item-title>
                  {{
                    selectedRoom[getOtherUserUid]['isBlocked']
                      ? 'Allow Chat'
                      : 'Block Chat'
                  }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item one-line @click="handleClearChat">
              <v-list-item-avatar class="ml-0 mr-0">
                <v-icon small>fa-list</v-icon>
              </v-list-item-avatar>
              <v-list-item-content class="ml-2 mr-0">
                <v-list-item-title>{{
                  selectedRoom[getOtherUserUid]['profile']['isSupport'] == true
                    ? 'Delete Support Chatroom & all chats'
                    : 'Clear all chats'
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="handlePasscode">
              <v-list-item-avatar class="ml-0 mr-0">
                <v-icon small>fa-lock</v-icon>
              </v-list-item-avatar>
              <v-list-item-content class="ml-2 mr-0">
                <v-list-item-title>
                  {{
                    selectedRoom[getMyUID]['passcode'] != null &&
                    selectedRoom[getMyUID]['passcode'] != ''
                      ? 'Remove'
                      : 'Set'
                  }}
                  Passcode for this Chat Room
                </v-list-item-title>
                <v-list-item-subtitle v-if="!currentUser.email">
                  Email is missing.
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item three-line>
              <v-list-item-avatar class="ml-0 mr-0">
                <v-icon small>fa-check-double</v-icon>
              </v-list-item-avatar>
              <v-list-item-content class="ml-2 mr-0">
                <v-list-item-title>Read Receipts</v-list-item-title>
                <v-list-item-subtitle>
                  Read receipt option will also hide/show your online status
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-switch
                color="primary"
                value
                :input-value="selectedRoom[getMyUID]['readReceipts']"
                @click="toggleReadReceiptsUser"
              ></v-switch>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </v-list-item>
    <v-card v-if="loader" class="hint-card" elevation="0">
      <div>
        <v-img
          height="50"
          width="50"
          src="@/assets/images/Discuss-1s-200px.gif.gif"
        ></v-img>
      </div>
    </v-card>
    <v-list
      :v-else-if="messages && messages.length"
      class="message-list"
      :class="{ 'reply-selected': isReply && replyMessage }"
    >
      <v-list-item :key="index" v-for="(message, index) in filterMessage()">
        <chat
          :message="message"
          :readReceipts="readReceipts"
          :isLastIndex="index === messages.length - 1"
          @openFilePreview="openFilePreview"
          @forwardClick="forwardClick"
          @replyClick="replyClick"
          @deleteClick="deleteClick"
          @copyClick="copyClick"
        >
        </chat>
      </v-list-item>
    </v-list>
    <!-- <v-card
      v-else-if="messages && messages.length == 0"
      class="hint-card"
      elevation="0"
    >
      <p>No messages yet</p>
    </v-card> -->
    <form
      class="chat-details-footer"
      v-if="
        selectedRoom[getMyUID]['isBlocked'] == false &&
        selectedRoom[getOtherUserUid]['isBlocked'] == false
      "
      :class="{ 'reply-selected': isReply && replyMessage }"
      @submit.prevent="
        sendMessage(
          '',
          textMessage,
          selectedRoom,
          getMyUID,
          getOtherUserUid,
          replyMessage
        )
      "
    >
      <v-row
        class="ma-0 pa-0 pr-5 mb-3 flex-nowrap"
        style="background-color: #e5dddd; height: 70px"
        v-if="isReply && replyMessage"
      >
        <v-col>
          <div
            class="text-Subtitle2 text-truncate"
            style="height: 20px; max-width: 800px"
          >
            {{
              replyMessage.fromUID == getMyUID
                ? 'You'
                : getOtherUser(selectedRoom).profile.name.toUpperCase()
            }}
          </div>
          <div
            v-if="
              replyMessage['payload']['medialUrl'] ||
              (replyMessage['payload']['message'] &&
                replyMessage['payload']['message'].startsWith('http') &&
                replyMessage['payload']['message'].includes(
                  'firebasestorage.googleapis.com'
                ))
            "
            class="text-subtitle text-truncate"
            style="height: 50px; max-width: 800px"
          >
            <template v-if="replyMessage['payload']['medialType'] == 'image'">
              <v-icon x-small color="grey" class="mr-1"> fa-image </v-icon>
              image
            </template>

            <template
              v-else-if="replyMessage['payload']['medialType'] == 'file'"
            >
              <v-icon x-small color="grey" class="mr-1"> fa-file-alt </v-icon>
              file
            </template>

            <template
              v-else-if="replyMessage['payload']['medialType'] == 'video'"
            >
              <v-icon x-small color="grey" class="mr-1"> fa-video </v-icon>
              video
            </template>
          </div>
          <div
            v-else-if="replyMessage['payload']['message']"
            class="text-subtitle text-truncate"
            style="height: 50px; max-width: 800px"
          >
            {{ replyMessage['payload']['message'] }}
          </div>
        </v-col>
        <template v-if="replyMessage['payload']['medialType'] == 'image'">
          <v-img
            v-if="replyMessage['payload']['medialUrl']"
            :src="replyMessage['payload']['medialUrl']"
            style="
              max-width: 50px;
              height: 50px;
              width: 50px;
              margin-right: 15px;
              margin-bottom: 5px;
              margin-top: 8px;
            "
          ></v-img>
        </template>
        <v-icon class="mb-6" small @click="closeReply">fa-times-circle</v-icon>
      </v-row>
      <v-row style="margin: 0" class="flex-nowrap">
        <v-menu offset-y v-model="showEmoji" :close-on-content-click="false">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              large
              color="#ff9800"
              class="mr-1"
              style="width: 40px; height: 48px"
            >
              <div style="font-size: 30px">😀</div>
              <!-- <v-icon> fa-smile </v-icon> -->
            </v-btn>
          </template>
          <div ref="emojiRef" />
          <!-- <VEmojiPicker @select="selectEmoji" /> -->
        </v-menu>
        <input
          type="file"
          accept="image/*"
          ref="imagePicker"
          class="d-none"
          max-file-size="1000"
          multiple
          @change="inputClickFileSelector"
        />
        <input
          type="file"
          accept="video/*"
          ref="videoPicker"
          class="d-none"
          @change="inputClickFileSelector"
        />
        <input
          type="file"
          accept=".xlsx, .xls, .doc, .docx,.ppt, .pptx,.txt,.pdf"
          ref="filePicker"
          class="d-none"
          multiple
          @change="inputClickFileSelector"
        />
        <v-speed-dial v-model="optionAttachment">
          <template v-slot:activator>
            <v-btn
              v-model="optionAttachment"
              dark
              icon
              small
              color="blue"
              class="mr-2"
              style="width: 40px; height: 48px"
            >
              <v-icon v-if="optionAttachment"> fa-times </v-icon>
              <v-icon v-else> fa-paperclip </v-icon>
            </v-btn>
          </template>
          <v-btn fab dark medium color="blue" @click="openPicker('image')">
            <v-icon>fa-image</v-icon>
          </v-btn>
          <v-btn fab dark medium color="red" @click="openPicker('video')">
            <v-icon>fa-video</v-icon>
          </v-btn>
          <v-btn fab dark medium color="purple" @click="openPicker('file')">
            <v-icon>fa-file-alt</v-icon>
          </v-btn>
        </v-speed-dial>
        <v-textarea
          v-model="textMessage"
          placeholder="Type your message.."
          auto-grow
          solo
          :rows="textareaRows"
          ref="textFieldRef"
          @keydown.shift.enter.exact="handleKeyEnter"
          @keydown.enter.exact.prevent="
            sendMessage(
              '',
              textMessage,
              selectedRoom,
              getMyUID,
              getOtherUserUid,
              replyMessage
            )
          "
          @keydown.delete="handleKeyEnter($event, true)"
        />

        <v-btn
          class="ml-2 mr-0"
          style="width: 40px; height: 48px"
          color="blue"
          elevation="0"
          icon
          small
          type="submit"
        >
          <v-icon>fa-paper-plane</v-icon>
        </v-btn>
      </v-row>
    </form>
    <h4
      class="chat-details-footer mb-3"
      align="center"
      justify="center"
      v-else-if="selectedRoom[getOtherUserUid]['isBlocked']"
    >
      You have blocked this user
    </h4>
    <h4
      class="chat-details-footer mb-3"
      align="center"
      justify="center"
      v-else-if="selectedRoom[getMyUID]['isBlocked']"
    >
      You have been blocked by other user
    </h4>
    <!-- <div class="chat-details-footer" v-else-if="foundTextList.length">
      <p>Found {{ foundTextList.length }} results</p>
      <v-btn
        @click="
          if (currInd < foundTextList.length - 1) {
            currInd++;
            foundTextList[currInd].scrollIntoView({ block: 'center' });
          }
        "
        >next</v-btn
      >
      <v-btn
        @click="
          if (currInd > 0) {
            currInd--;
            foundTextList[currInd].scrollIntoView({ block: 'center' });
          }
        "
        >prev</v-btn
      >
    </div> -->

    <v-overlay
      v-if="fileList.length"
      v-model="uploadPreview"
      class="file-preview-overlay"
    >
      <v-btn class="close-preview" icon large @click="uploadPreview = false"
        ><v-icon>fa-times</v-icon>
      </v-btn>
      <div class="file-preview-view upload">
        <v-col style="position: absolute; top: 0px">
          <div class="title-section">
            <h4>Selected Files</h4>
            <h6 v-if="isExceedFileLimit" style="color: red">
              some file exceeds max file size {{ maxFileSize }} MB and won't be
              uploaded
            </h6>
            <h6 v-else style="color: antiquewhite">
              Note: Max File Size is {{ maxFileSize }} MB
            </h6>
          </div>
          <v-carousel
            :continuous="false"
            v-model="fileIndex"
            class="mt-5 mb-10 carousel"
            hide-delimiters
            :show-arrows="fileList.length > 1"
          >
            <v-carousel-item :key="index" v-for="(item, index) in fileList">
              <v-sheet height="100%" width="100%" tile>
                <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
                  style="position: relative"
                >
                  <div class="text-h6">
                    <h6
                      v-if="item.file.size / 1024 / 1024 > maxFileSize"
                      style="color: red"
                    >
                      This file exceeds max file size {{ maxFileSize }} MB and
                      won't be uploaded
                    </h6>
                  </div>
                  <template v-if="selectOptionType == 'image'">
                    <!-- <img
                      ref="thumbnil"
                      class="image-view thumbnail"
                      :src="item.previewUrl"
                    /> -->
                    <image-zoom
                      ref="thumbnil"
                      :regular="item.previewUrl"
                      :zoom-amount="3"
                      :click-zoom="true"
                      img-class="image-view thumbnail"
                    />
                  </template>
                  <template
                    class="video-player-box"
                    v-else-if="selectOptionType == 'video'"
                  >
                    <video-player
                      ref="videoPlayer"
                      :options="{
                        muted: true,
                        language: 'en',
                        playbackRates: [0.7, 1.0, 1.5, 2.0],
                        sources: [
                          {
                            type: 'video/mp4',
                            src: item.previewUrl,
                          },
                        ],
                      }"
                      :playsinline="true"
                      customEventName="customstatechangedeventname"
                      @play="onPlayerPlay($event)"
                      @pause="onPlayerPause($event)"
                      @statechanged="playerStateChanged($event)"
                      @ready="playerReadied"
                    >
                    </video-player>
                  </template>
                  <template v-if="selectOptionType == 'file'">
                    <v-icon>fa-file</v-icon>
                    <h3 class="ml-5" style="color: white">
                      {{ item.file.name }}
                    </h3>
                  </template>
                </v-row>
              </v-sheet>
            </v-carousel-item>
          </v-carousel>
          <div class="footer-section">
            <v-row align="center" class="ma-0">
              <v-btn icon @click="deleteFile" style="">
                <v-icon medium color="red">fa-trash</v-icon>
              </v-btn>
              <h4 style="margin-left: 20px; color: antiquewhite">
                {{ fileIndex + 1 }} / {{ fileList.length }}
              </h4>
              <h4 style="margin-left: 20px; color: antiquewhite">
                Size:
                {{ (fileList[fileIndex].file.size / 1024 / 1024).toFixed(2) }}
                MB
              </h4>
              <v-spacer></v-spacer>
              <v-btn
                :color="isExceedFileLimit ? 'antiquewhite' : 'blue'"
                @click="onUpload"
              >
                Upload
              </v-btn>
            </v-row>
            <h6 style="color: antiquewhite">
              Note: Dont refresh or close tab while uploading files.
            </h6>
          </div>
        </v-col>
      </div>
    </v-overlay>

    <v-overlay
      v-if="selectedChatMessage && mediaChats.length"
      v-model="dialog"
      class="file-preview-overlay"
    >
      <v-btn icon large @click="dialog = false" class="close-preview"
        ><v-icon>fa-times</v-icon>
      </v-btn>
      <div class="file-preview-view">
        <v-carousel
          :continuous="false"
          :value="mediaIndex"
          @input="setMediaIndex"
          class="mt-5 mb-10"
          :show-arrows="mediaChats.length > 1"
          hide-delimiters
        >
          <v-carousel-item :key="index" v-for="(item, index) in mediaChats">
            <div>
              <h4>
                {{
                  item.fromUID == getMyUID
                    ? 'You'
                    : getOtherUser(selectedRoom).profile.name.toUpperCase()
                }}
              </h4>
              <h5>
                {{ getTimeAgo(item['payload']['timestamp']) }}
              </h5>
            </div>

            <v-sheet height="100%" width="100%" tile>
              <template v-if="item['payload']['medialType'] == 'image'">
                <!-- <v-img
                  contain
                  class="image-view"
                  :src="item['payload']['medialUrl']"
                >
                </v-img> -->
                <image-zoom
                  :regular="item['payload']['medialUrl']"
                  :zoom-amount="3"
                  :click-zoom="true"
                  img-class="image-view"
                />
              </template>
              <template
                class="video-player-box"
                v-else-if="item['payload']['medialType'] == 'video'"
              >
                <video-player
                  ref="videoPlayer"
                  :options="{
                    muted: true,
                    language: 'en',
                    playbackRates: [0.7, 1.0, 1.5, 2.0],
                    sources: [
                      {
                        type: 'video/mp4',
                        src: item['payload']['medialUrl'],
                      },
                    ],
                  }"
                  :playsinline="true"
                  customEventName="customstatechangedeventname"
                  @play="onPlayerPlay($event)"
                  @pause="onPlayerPause($event)"
                  @statechanged="playerStateChanged($event)"
                  @ready="playerReadied"
                >
                </video-player>
              </template>
            </v-sheet>
          </v-carousel-item>
        </v-carousel>
      </div>
    </v-overlay>

    <v-dialog
      v-if="selectedChatMessage"
      :value="isForward"
      @input="setIsForward"
      width="400px"
      height="400px"
      class="forward_dialog"
    >
      <v-card>
        <v-card-title> Forward Message To</v-card-title>
        <v-card-text>
          <ChatRoomsSidebar
            :isForwardDialogShow="isForward"
            :forwardChatData="selectedChatMessage"
            @close-dialog="closeForwardDialog"
          />
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-if="selectedChatMessage"
      :value="isDeleteChat"
      @input="setIsDeleteChat"
      max-width="400px"
      class="delete_dialog"
    >
      <v-card>
        <v-card-title>Delete message?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="red darken-1" text @click="setIsDeleteChat(false)">
            Cancel
          </v-btn>
          <v-btn
            color="red darken-1"
            text
            @click="
              deleteMessage({
                chatData: selectedChatMessage,
                isMe: true,
                showDialog: true,
                previousChat: previousChat,
                isLastIndex: isLastIndex,
                batch: null,
              })
            "
          >
            Only For Me
          </v-btn>
          <v-btn
            v-if="isEveryoneDelete(selectedChatMessage)"
            color="red darken-1"
            text
            @click="
              deleteMessage({
                chatData: selectedChatMessage,
                isMe: false,
                showDialog: true,
                previousChat: previousChat,
                isLastIndex: isLastIndex,
                batch: null,
              })
            "
          >
            For Everyone
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showPasscodeDialog" :max-width="320">
      <v-card :max-width="320">
        <v-card-title>
          <v-col>
            <h5 class="mb-10">
              Please {{ newPasscode.length == 4 ? 're-' : '' }}enter the
              passcode
            </h5>

            <v-otp-input
              id="new"
              v-if="newPasscode.length < 4"
              v-model="newPasscode"
              type="password"
              length="4"
              dark
            ></v-otp-input>
            <v-otp-input
              id="confirm"
              v-else
              v-model="confirmPasscode"
              type="password"
              length="4"
              dark
            ></v-otp-input>
            <h6 class="mt-10">
              Note: if you forget your passcode and then reset your passcode,
              all your previous messages for this chat room will be deleted for
              privacy purpose.
            </h6>
          </v-col>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="showPasscodeDialog = false">
            Cancel
          </v-btn>
          <v-btn color="red darken-1" text @click="setPasscode">
            Set Passcode
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      :value="isBlockDialog"
      @input="setIsBlockDialog"
      max-width="320px"
      class="block_chat_dialog"
    >
      <v-card>
        <v-card-title
          >Are you sure want to
          {{ selectedRoom[getOtherUserUid]['isBlocked'] ? 'un' : '' }}block this
          user?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="setIsBlockDialog(false)">
            Cancel
          </v-btn>
          <v-btn
            color="red darken-1"
            text
            @click="blockAllowUser(getOtherUserUid)"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="msgDialog" max-width="320px" class="msg_dialog">
      <v-card>
        <v-card-title> {{ msgDialogText }}</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="yesMsgDialogAction">
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import ChatMixin from './ChatMixin';
import Chat from './Chat';
import { firestore, storage } from '../plugins/fireinit';
import ChatRoomsSidebar from './ChatRoomsSidebar.vue';
import 'video.js/dist/video-js.css';
import { videoPlayer } from 'vue-video-player';
import { differenceInMinutes } from 'date-fns';
import { VEmojiPicker } from 'v-emoji-picker';
import { Picker } from 'emoji-picker-element';
import { mapState, createNamespacedHelpers } from 'vuex';
import { db } from '../services/db';
import { decrypt } from '../services/encryption_service';
import { getMediaType } from '../services/utils';
import imageZoom from 'vue-image-zoomer';

const {
  mapState: chatState,
  mapMutations: chatMutations,
  mapActions: chatActions,
} = createNamespacedHelpers('chat');

export default {
  name: 'ChatDetails',
  components: {
    Chat,
    videoPlayer,
    ChatRoomsSidebar,
    VEmojiPicker,
    imageZoom,
  },
  async created() {
    this.getMyUID = sessionStorage.getItem('uid');

    if (this.selectedRoom) {
      // this.setChatLoader(true);
      const otherUser = this.getOtherUser(this.selectedRoom);
      this.readReceipts = otherUser['readReceipts'];
      this.getOtherUserUid = otherUser['profile']['uid'];
      this.setOnlineStatus({
        chatRoomId: this.selectedRoom['id'],
        uid: this.getMyUID,
        status: true,
      });
      this.selectedRoom[this.getMyUID]['online'] = true;
      this.getAllMessages(this.selectedRoom);
    }
  },
  mounted() {
    window.onbeforeunload = () => {
      this.goBack();
      return true;
    };
  },
  watch: {
    showEmoji(val) {
      if (val) {
        this.$nextTick(() => {
          // console.log(this.$refs['emojiRef'], this.$refs['emojiRef'].children);
          if (
            this.$refs['emojiRef'] &&
            this.$refs['emojiRef'].children?.length === 0
          ) {
            const emojiEl = new Picker();
            this.$refs['emojiRef'].append(emojiEl);
            if (emojiEl)
              emojiEl.addEventListener('emoji-click', (event) =>
                this.selectEmoji({ data: event.detail?.unicode })
              );
          }
        });
      }
    },
    async selectedRoom(val, oldVal) {
      // console.log(val.id, oldVal.id, val.id !== oldVal.id);
      if (val && oldVal && val.id !== oldVal.id) {
        // this.setChatLoader(true);
        // this.setMessages(null);
        if (this.isReply == true) {
          this.replyMessage = null;
          this.isReply = false;
        }
        this.setOnlineStatus({
          chatRoomId: oldVal.id,
          uid: this.getMyUID,
          status: false,
        });
        // console.log('old room = ' + oldVal.id);
        const otherUser = this.getOtherUser(val);
        this.readReceipts = otherUser['readReceipts'];
        this.getOtherUserUid = otherUser['profile']['uid'];
        if (this.snapHandlerUnsubscribe) {
          this.snapHandlerUnsubscribe();
        }
        this.setOnlineStatus({
          chatRoomId: val.id,
          uid: this.getMyUID,
          status: true,
        });
        val[this.getMyUID]['online'] = true;
        this.getAllMessages(val);
      }
    },
  },
  data() {
    return {
      readReceipts: false,
      getOtherUserUID: null,
      textMessage: '',
      getMyUID: null,
      dialog: false,
      playerOptions: null,
      replyMessage: null,
      isReply: false,
      showEmoji: false,
      optionAttachment: false,
      selectOptionType: '',
      fileList: [],
      uploadPreview: false,
      fileIndex: 0,
      isExceedFileLimit: false,
      maxFileSize: 10.0,
      textareaRows: 1,
      showPasscodeDialog: false,
      newPasscode: '',
      confirmPasscode: '',
      msgDialog: false,
      msgDialogText: '',
      msgDialogActionType: '',
      snapHandlerUnsubscribe: null,
      unsubscribeOnSnapshotChats: null,
    };
  },
  mixins: [ChatMixin],
  computed: {
    ...mapState({
      colorJson: (state) => state.colorJson,
      user: (state) => state.user,
    }),
    ...chatState({
      sessionUID: (state) => state.sessionUID,
      findChatText: (state) => state.findChatText,
      selectedRoom: (state) => state.selectedRoom,
      messages: (state) => state.messages,
      loader: (state) => state.chatLoader,
      isForward: (state) => state.isForward,
      searchToggle: (state) => state.searchToggle,
      chatDetailSettings: (state) => state.chatDetailSettings,
      selectedChatMessage: (state) => state.selectedChatMessage,
      isDeleteChat: (state) => state.isDeleteChat,
      isBlockDialog: (state) => state.isBlockDialog,
      snackbar: (state) => state.snackbar,
      snackBartext: (state) => state.snackBartext,
      previousChat: (state) => state.previousChat,
      mediaIndex: (state) => state.mediaIndex,
      mediaChats: (state) => state.mediaChats,
    }),
  },
  methods: {
    ...chatMutations([
      'setSearchText',
      'setCurrentRoom',
      'setIsForward',
      'setMessages',
      'setSearchToggle',
      'setSnackbar',
      'setSnackBartext',
      'setChatDetailSettings',
      'setSelectedChatMessage',
      'setIsDeleteChat',
      'setIsBlockDialog',
      'setPreviousChat',
      'setChatLoader',
      'setMediaIndex',
      'setMediaChats',
    ]),
    ...chatActions([
      'deleteMessage',
      'deleteChatroom',
      'toChatRoomPasscode',
      'blockAllowUser',
      'toggleReadReceiptsUser',
      'deleteAllMsg',
      'setOnlineStatus',
      //  'setSeenStatus',
    ]),
    goBack() {
      if (this.selectedRoom) {
        this.setOnlineStatus({
          chatRoomId: this.selectedRoom.id,
          uid: this.getMyUID,
          status: false,
        });
      }
      this.setCurrentRoom(null);
    },
    async getAllMessages(chatroom) {
      const setUpMessage = (list) => {
        if (list) {
          const messages = list.sort((d1, d2) => d1['date'] - d2['date']);
          this.setMessages(messages);
          this.setChatLoader(false);

          // const getOtherUserUid = chatroom[this.sessionUID]['partnerId'];
          this.$forceUpdate();
          if (messages && messages.length > 0) {
            this.$nextTick(() => {
              document
                .querySelector('.message-list .v-list-item:last-child')
                ?.scrollIntoView();
            });

            // if (
            //   getOtherUserUid &&
            //   (chatroom[this.sessionUID]['online'] == true ||
            //     chatroom[this.sessionUID]['readReceipts'] == false)
            // ) {
            //   this.setSeenStatus({
            //     uid: getOtherUserUid,
            //     chatRoomId: chatroom['id'],
            //   });
            // }

            const reversed = [...messages];
            reversed.reverse();
            if (reversed.length > 1) {
              this.setPreviousChat(reversed[1]);
              db.chats.put(reversed[0]);
            } else if (reversed.length == 1) {
              this.setPreviousChat(reversed[0]);
              db.chats.put(reversed[0]);
            } else {
              this.setPreviousChat(null);
            }

            const allmediaChats = messages.filter(
              (message) =>
                message['payload']['medialType'] == 'video' ||
                message['payload']['medialType'] == 'image'
            );
            this.setMediaChats(allmediaChats);
          }
        } else {
          this.setMessages([]);
          this.setChatLoader(false);

          this.$forceUpdate();
        }
      };
      if (chatroom) {
        this.setChatLoader(true);
        this.setMessages([]);
        const localMessage = await db.chats
          .where('chatRoomId')
          .equals(chatroom['id'])
          ?.toArray();
        setUpMessage(localMessage);

        if (localMessage?.length == 0) {
          this.setChatLoader(true);
        }

        try {
          if (this.unsubscribeOnSnapshotChats) {
            this.unsubscribeOnSnapshotChats();
          }
          this.unsubscribeOnSnapshotChats = firestore
            .collection('chats')
            .where('chatRoomId', '==', `${chatroom.id}`)
            .where(`deletedFor.${this.sessionUID}`, '==', false)
            .onSnapshot(async (snapshot) => {
              console.log(snapshot.size);
              if (snapshot.size) {
                var batch = firestore.batch();
                const getOtherUserUid = chatroom[this.sessionUID]['partnerId'];
                // const seenChats = [];

                const chatsData = snapshot.docs?.map((chat) => {
                  const data = chat.data();
                  data['id'] = chat.id;
                  if (data?.seen == false && data?.fromUID == getOtherUserUid) {
                    if (
                      chatroom[this.sessionUID]['online'] == true ||
                      chatroom[this.sessionUID]['readReceipts'] == false
                    ) {
                      batch.update(chat.ref, { seen: true });
                      db.chats.update(chat.id, { seen: true });
                    }
                  }

                  const keyStr = data['chatRoomId'];
                  const ivStr = data['id'];
                  const encrptData = data['data'];

                  data['payload'] = decrypt(keyStr, ivStr, encrptData);
                  if (data['payload']['medialType'] == 'video') {
                    data['payload']['medialType'] = getMediaType(
                      data['payload']['medialUrl']
                    );
                  }
                  if (data['payload']['repliedToChatId'] != null) {
                    if (
                      data['payload']['repliedToChatContent'].startsWith(
                        'http'
                      ) &&
                      data['payload']['repliedToChatContent'].includes(
                        'firebasestorage.googleapis.com'
                      )
                    ) {
                      data['payload']['repliedToChatMedialType'] = getMediaType(
                        data['payload']['repliedToChatContent']
                      );
                    }
                  }

                  if (data['payload']['medialType'] == '') {
                    data['message'] = data['payload']['message'];
                  }

                  // if (data.seen == false && data?.fromUID == getOtherUserUid) {
                  //   seenChats.push(data);
                  // }

                  return data;
                });
                if (
                  getOtherUserUid &&
                  (chatroom[this.sessionUID]['online'] == true ||
                    chatroom[this.sessionUID]['readReceipts'] == false)
                ) {
                  // db.chats.bulkPut(seenChats);

                  batch.commit();
                }

                setUpMessage(chatsData);
              } else {
                setUpMessage(null);
              }
            });
        } catch (e) {
          console.log(e);
        }
      }
    },
    filterMessage() {
      if (this.findChatText) {
        return this.messages.filter((message) => {
          return message.payload.message.includes(this.findChatText);
        });
      }
      return this.messages;
    },
    handleClearChat() {
      if (
        this.selectedRoom[this.getOtherUserUid]['profile']['isSupport'] == true
      ) {
        this.msgDialog = true;
        this.msgDialogText =
          'Are you sure want to delete your support chatroom & messages?';
        this.msgDialogActionType = 'delete support chatroom';
      } else {
        this.msgDialog = true;
        this.msgDialogText = 'Are you sure want to delete your messages?';
        this.msgDialogActionType = 'clear chats';
      }
    },
    async yesMsgDialogAction() {
      this.msgDialog = false;
      this.msgDialogText = '';
      if (this.msgDialogActionType == 'delete support chatroom') {
        console.log('delete chatroom');
        const msg = await this.deleteChatroom();
        this.setSnackBartext(msg);
      }
      if (this.msgDialogActionType == 'clear chats') {
        console.log('clear chats');
        const keys = this.messages.map((e) => e.id);
        db.chats.bulkDelete(keys);
        const msg = await this.deleteAllMsg();
        this.setSnackBartext(msg);
      }
      this.msgDialogActionType = '';
    },
    async handlePasscode() {
      this.setChatDetailSettings(false);

      if (!this.currentUser.email) {
        this.msgDialog = true;
        this.msgDialogText =
          'Please update email in your profile to set passcode';
        return;
      }
      if (
        this.selectedRoom[this.getMyUID]['passcode'] != null &&
        this.selectedRoom[this.getMyUID]['passcode'] != ''
      ) {
        await this.toChatRoomPasscode({
          chatRoomId: this.selectedRoom['id'],
          uid: this.getMyUID,
          pass: '',
        });
        this.setSnackBartext('passcode removed');
      } else {
        this.newPasscode = '';
        this.confirmPasscode = '';
        this.showPasscodeDialog = true;
      }
      return;
    },
    async setPasscode() {
      if (
        this.newPasscode.length > 0 &&
        this.confirmPasscode.length > 0 &&
        this.newPasscode == this.confirmPasscode &&
        this.confirmPasscode.length == 4
      ) {
        await this.toChatRoomPasscode({
          chatRoomId: this.selectedRoom['id'],
          uid: this.getMyUID,
          pass: this.confirmPasscode,
        });
        this.newPasscode = '';
        this.confirmPasscode = '';
        this.showPasscodeDialog = false;
        this.setSnackBartext('passcode is added');
      } else {
        this.newPasscode = '';
        this.confirmPasscode = '';
        this.setSnackBartext('passcode & confirm passcode is not matched');
      }
    },
    getColor(alp) {
      const clr = colors(alp);
      return clr;
    },
    handleKeyEnter(e, minus) {
      if (minus && this.textareaRows > 0) {
        this.textareaRows -= 1;
      } else if (e.target instanceof HTMLElement && this.textareaRows < 3) {
        this.textareaRows = e.target.rows++;
      }
    },
    selectEmoji(emoji) {
      this.showEmoji = false;
      console.log(emoji);
      const textFieldRef = this.$refs['textFieldRef'];
      if (textFieldRef?.$refs?.['input']) {
        const selectionStart = textFieldRef.$refs['input'].selectionStart;
        const start = this.textMessage.substr(0, selectionStart);
        const end = this.textMessage.substr(selectionStart);
        this.$nextTick(() => {
          this.textMessage = start + emoji.data + end;
        });
      } else {
        this.textMessage += emoji.data || '';
      }
      textFieldRef?.focus();
    },
    openFilePreview(message) {
      this.dialog = true;
      this.setSelectedChatMessage(message);
      const index = this.mediaChats.indexOf(message);
      if (index >= 0) {
        this.setMediaIndex(index);
      }
      if (message['payload']['medialType'] == 'video') {
        this.playerOptions = {
          muted: true,
          language: 'en',
          playbackRates: [0.7, 1.0, 1.5, 2.0],
          sources: [
            {
              type: 'video/mp4',
              src: message['payload']['medialUrl'],
            },
          ],
        };
      }
    },
    forwardClick(message) {
      console.log('forwardClick');
      this.setIsForward(true);
      this.setSelectedChatMessage(message);
    },
    replyClick(message) {
      console.log('replyClick');
      this.isReply = true;
      this.replyMessage = message;
    },
    deleteClick(message, isLastIndex) {
      console.log('deleteClick');
      this.setIsDeleteChat(true);
      this.isLastIndex = isLastIndex;
      this.setSelectedChatMessage(message);
    },
    async copyClick(mytext) {
      try {
        await navigator.clipboard.writeText(mytext);
        this.setSnackBartext('Copied');
      } catch ($e) {
        this.setSnackBartext('Cannot copy');
      }
    },
    isEveryoneDelete(message) {
      const minute = differenceInMinutes(new Date(), new Date(message.date));
      // console.log(message);
      // console.log(minute, this.getMyUID === message.fromUID);
      return minute < 5 && this.getMyUID === message.fromUID;
    },
    closeReply() {
      console.log('closeReply');
      this.isReply = false;
      this.replyMessage = null;
    },
    closeForwardDialog() {
      console.log('closeForwardDialog');
      this.setIsForward(false);
      this.setSelectedChatMessage(null);
    },
    onPlayerPlay(player) {
      console.log('player play!', player);
    },
    onPlayerPause(player) {
      console.log('player pause!', player);
    },
    playerStateChanged(playerCurrentState) {
      console.log('player current update state', playerCurrentState);
    },
    playerReadied(player) {
      console.log('the player is readied', player);
    },
    inputClickFileSelector(event) {
      this.fileIndex = 0;
      this.isExceedFileLimit = false;
      const files = event.target.files;
      this.fileList = [];
      for (let i = 0; i < files.length; i++) {
        this.fileList.push({
          file: files.item(i),
          previewUrl: URL.createObjectURL(files.item(i)),
          uploadValue: 0,
        });
      }
      console.log(this.fileList);
      this.uploadPreview = true;
      this.checkFileLimit();
      event.target.value = null;
    },
    checkFileLimit() {
      const exceedFileList = this.fileList.filter((element) => {
        const fileSize = element.file.size / 1024 / 1024;
        return fileSize > this.maxFileSize;
      });
      this.isExceedFileLimit = exceedFileList.length > 0;
    },
    async onUpload() {
      if (this.isExceedFileLimit) {
        return;
      }
      console.log('onUpload', this.selectedRoom);
      const promises = [];
      for (let index = 0; index < this.fileList.length; index++) {
        const file = this.fileList[index].file;
        const prom = this.sendMessage(
          this.selectOptionType,
          '',
          this.selectedRoom,
          this.getMyUID,
          this.getOtherUserUid,
          this.replyMessage,
          file.name.substring(file.name.lastIndexOf('.') + 1)
        );
        promises.push(prom);
      }
      this.uploadPreview = false;
      const uploadedFiles = await Promise.all(promises);
      uploadedFiles.forEach((chatData, index) => {
        const folder = `chat/${this.getMyUID}`;
        const fileName = `chat-${this.getMyUID}-${chatData.id}.${chatData.ext}`;

        const storageRef = storage
          .ref(`${folder}/${fileName}`)
          .put(this.fileList[index].file);
        storageRef.on(
          `state_changed`,
          (snapshot) => {
            this.fileList[index].uploadValue =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          },
          (error) => {
            console.log(error.message);
          },
          () => {
            this.fileList[index].uploadValue = 100;
            storageRef.snapshot.ref.getDownloadURL().then((url) => {
              console.log(url);
              chatData.medialUrl = url;
              this.updateMessage(chatData.id, chatData);
            });
          }
        );
      });
    },
    openPicker(source) {
      this.selectOptionType = source;
      this.$refs[`${source}Picker`]?.click();
    },
    deleteFile() {
      this.fileList.splice(this.fileIndex, 1);
      if (this.fileIndex > 0) {
        this.fileIndex = this.fileIndex - 1;
      }
      console.log(this.fileIndex);
      console.log(this.fileList);
      this.uploadPreview = this.fileList.length > 0;
      this.checkFileLimit();
    },
  },
};
</script>
