<template>
  <v-main class="main-wrapper">
    <v-card class="login-card" v-if="!checkUserLoggedIn && !currentUser">
      <v-container>
        <v-row>
          <v-col cols="12" sm="6">
            <div class="logo-view">
              <div>
                <v-img class="logo" src="@/assets/images/visicardia.png" alt="" />
              </div>
            </div>
          </v-col>
          <v-col cols="12" sm="6">
            <div class="login">
              <v-overlay :value="loading"></v-overlay>
              <div v-if="loading" class="loader">
                <v-progress-circular indeterminate color="red"></v-progress-circular>
              </div>
              <v-snackbar v-model="snackbar" :timeout="4000">
                {{ snackBartext }}
              </v-snackbar>
              <div class="contentSpace" style="font-size: 18px; font-weight: 900">
                Welcome
              </div>
              <p style="font-size: 24px; font-weight: 400">Login to continue</p>
              <div class="mobile-text-field contentSpace">
                <vue-country-code searchPlaceholderText="Search country" :enableSearchField="true"
                  :enabledCountryCode="true" :enabledFlags="true" :preferredCountries="['in', 'us', 'gb']"
                  @onSelect="onSelect">
                </vue-country-code>
                <v-text-field v-model="mobile" label="Mobile No." style="box-align: center"
                  @keydown.enter="sendOtp"></v-text-field>
              </div>
              <v-btn class="button" color="red" textColor="white" elevation="0.0" @click="sendOtp">
                Send Otp
              </v-btn>
              <template v-if="smsSent">
                <v-otp-input class="contentSpace" v-model="otp" length="6" type="number" plain
                  style="margin-bottom: 20px" @finish="verifyOtp" />
                <v-btn class="button" color="red" textColor="white" elevation="0.0" @click="verifyOtp">
                  Verify Otp
                </v-btn>
              </template>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <v-card v-else style="margin: 0 auto; max-width: 300px">
      <v-list-item> Loading...</v-list-item>
    </v-card>
    <div id="recaptcha-container"></div>
  </v-main>
</template>

<script>
import { mapActions, mapState, createNamespacedHelpers } from 'vuex';
import { firebase, auth } from '../plugins/fireinit';
const { mapMutations: chatMutations, mapActions: chatActions } =
  createNamespacedHelpers('chat');
import { db } from '../services/db';

export default {
  name: 'WebLogin',
  data: () => ({
    mobile: '',
    otp: '',
    appVerifier: '',
    countryCode: '',
    loading: false,
    snackbar: false,
    snackBartext: '',
    smsSent: false,
    checkUserLoggedIn: true,
  }),
  computed: {
    ...mapState({
      currentUser: (state) => state.user,
    }),
  },
  async created() {
    if (!this.currentUser) {
      this.loading = true;
      this.initReCaptcha();
      auth.onAuthStateChanged(async (user) => {
        if (user) {
          this.checkUserLoggedIn = false;
          sessionStorage.setItem('uid', user.uid);
          await this.fetchCurrentUser(user.uid);
        } else {
          this.checkUserLoggedIn = false;
          const deleteKeys = await db.chatrooms.orderBy('id').keys();
          db.chatrooms.bulkDelete(deleteKeys);
          const deleteKeys2 = db.chats.orderBy('id').keys();
          db.chats.bulkDelete(deleteKeys2);
        }
        const uid = sessionStorage.getItem('uid');
        this.setSessionUID(uid);
        if (uid) {
          this.fetchChatRooms(uid);
          this.fetchChatroomAllMessages();
        }
        this.loading = false;
      });
    }
  },
  methods: {
    ...chatMutations(['setSessionUID']),
    ...mapActions(['fetchCurrentUser']),
    ...chatActions(['fetchChatRooms', 'fetchChatroomAllMessages']),
    onSelect({ dialCode }) {
      this.countryCode = '+' + dialCode;
    },
    sendOtp() {
      if (this.mobile.length < 10) {
        this.snackbar = true;
        this.snackBartext = 'Invalid Phone Number Format!';
      } else {
        this.loading = true;
        let phoneNumber = this.countryCode + this.mobile;

        let appVerifier = this.appVerifier;

        auth
          .signInWithPhoneNumber(phoneNumber, appVerifier)
          .then((confirmationResult) => {
            window.confirmationResult = confirmationResult;
            this.loading = false;
            this.snackbar = true;
            this.snackBartext = 'SMS sent';
            this.smsSent = true;
          })
          .catch((error) => {
            this.loading = false;
            this.snackbar = true;
            this.snackBartext = 'Error ! SMS not sent';
            this.smsSent = false;
          });
      }
    },
    verifyOtp() {
      if (this.mobile.length < 10) {
        this.snackbar = true;
        this.snackBartext = 'Invalid Phone Numbe Format !';
      } else if (this.otp.length != 6) {
        this.snackbar = true;
        this.snackBartext = 'Invalid OTP Format !';
      } else {
        let code = this.otp;
        this.loading = true;

        window.confirmationResult
          .confirm(code)
          .then((result) => {
            const user = result.user;
            this.fetchCurrentUser(user.uid);
            this.loading = false;
            this.smsSent = false;
          })
          .catch((error) => {
            console.log(error);
            this.loading = false;
            this.snackbar = true;
            this.snackBartext = 'Error ! Otp Invalid';
          });
      }
    },
    initReCaptcha() {
      setTimeout(() => {
        window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
          'recaptcha-container',
          {
            size: 'invisible',
            callback: (response) => { },
            'expired-callback': () => { },
          }
        );
        this.appVerifier = window.recaptchaVerifier;
      }, 1000);
    },
  },
};
</script>

<style lang="scss">
.main-wrapper {
  background-color: white;

  .login-card {
    max-width: 800px;
    max-height: 800px;
    display: flex;
    margin: 6rem auto;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: row;

    .logo-view {
      width: 100%;
      height: 100%;
      background-color: rgb(219, 61, 61);
      display: flex;
      align-items: center;
      justify-content: center;

      .logo {
        width: 100px;
        height: 100px;
      }

      @media screen and (max-width: 600px) {
        width: 250px;
        height: 250px;
        margin: 0 auto;
        margin-top: 3rem;
      }
    }

    .loader {
      align-items: center;
      display: flex;
      justify-content: center;
    }

    .login {
      padding: 1rem;
      margin: 3rem auto;
      width: 100%;
      height: 100%;

      @media screen and (max-width: 600px) {
        margin-top: 0;
      }

      .contentSpace {
        margin-top: 60px;

        @media screen and (max-width: 600px) {
          margin-top: 10px;
        }
      }

      .vue-country-select {
        display: flex;
        border: none;
        border-bottom: 1px solid grey;
        height: 28px;
        margin-right: 5px;

        .country-code {
          font-size: 16px;
          font-weight: bold;
          color: black;
          padding: 0 5px;
        }
      }

      .mobile-text-field {
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      .button {
        margin: auto;
        width: 100%;
        color: white;
        height: 50px;
        font-size: 15px;
        font-weight: 900;
      }
    }
  }
}
</style>
