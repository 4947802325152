export function colors(clr) {
  console.log(clr);
  var color = '';
  switch (clr) {
    case 'A':
      color = '#0000b4';
      break;
    case 'B':
      color = '#af0d66';
      break;
    case 'C':
      color = '#92f846';
      break;
    case 'D':
      color = '#ffc82f';
      break;
    case 'E':
      color = '#ffe400';
      break;
    case 'F':
      color = '#b9b9b9';
      break;
    case 'G':
      color = '#ebebde';
      break;
    case 'H':
      color = '#646464';
      break;
    case 'I':
      color = '#ffff00';
      break;
    case 'J':
      color = '#371370';
      break;
    case 'K':
      color = '#ffff96';
      break;
    case 'L':
      color = '#ca3e5e';
      break;
    case 'M':
      color = '#cdf53f';
      break;
    case 'N':
      color = '#0c4b64';
      break;
    case 'O':
      color = '#ff0000';
      break;
    case 'P':
      color = '#af9b32';
      break;
    case 'Q':
      color = '#000000';
      break;
    case 'R':
      color = '#254619';
      break;
    case 'S':
      color = '#792187';
      break;
    case 'T':
      color = '#538cd0';
      break;
    case 'U':
      color = '#009a25';
      break;
    case 'V':
      color = '#b2dccd';
      break;
    case 'W':
      color = '#ff98d5';
      break;
    case 'X':
      color = '#00004a';
      break;
    case 'Y':
      color = '#afc84a';
      break;
    case 'Z':
      color = '#3f190c';
      break;
    default:
      color = '#0000b4';
      break;
  }
  return color;
}
