<template>
  <v-container v-if="user" class="ma-0 pa-0">
    <v-list-item-avatar>
      <v-img
        v-if="user['profile']['imgUrl']"
        :src="user['profile']['imgUrl']"
      ></v-img>
      <v-avatar
        :color="
          colorJson && user && user['profile'] && user['profile']['name']
            ? colorJson[
                user['profile']['name'].substring(0, 1).toUpperCase()
              ] || '#f00'
            : '#ddd'
        "
        size="48"
        v-else
      >
        <span class="white--text text-h5">{{
          user['profile']['name'].substring(0, 1)
        }}</span>
      </v-avatar>
    </v-list-item-avatar>

    <v-list-item-content>
      <v-list-item-title>
        <v-row v-if="user" class="ma-0">
          <h4>{{ user['profile']['name'] }}</h4>
          <v-spacer></v-spacer>
          <v-icon class="mr-2" x-small color="blue" v-if="readReceipts"
            >fa-eye</v-icon
          >
          <v-icon class="mr-2" x-small v-else>fa-eye-slash</v-icon>
          <v-icon class="mr-2" x-small v-if="passcode">fa-lock</v-icon>
          <v-avatar v-if="unreadCount" color="red" size="18">
            <span class="white--text text-caption">
              {{ unreadCount > 9 ? '9+' : unreadCount }}
            </span>
          </v-avatar>
        </v-row>
      </v-list-item-title>
      <v-list-item-subtitle>
        <v-row class="ma-0">
          <template
            v-if="
              currentUser &&
              chatPayload &&
              currentUser.uid === chatPayload['fromUID']
            "
          >
            <v-icon
              v-if="chatData['seen'] && readReceipts"
              x-small
              color="blue"
              class="mr-1"
            >
              fa-check-double
            </v-icon>
            <v-icon v-else x-small color="grey" class="mr-1"> fa-check </v-icon>
          </template>
          <div
            class="font-weight-regular"
            style="color: green"
            v-if="typingUser && typingUser == true"
          >
            typing....
          </div>
          <template
            v-else-if="chatPayload && chatPayload['medialType'] == 'image'"
          >
            <v-icon x-small color="grey" class="mr-1"> fa-image </v-icon>
            image
          </template>

          <template
            v-else-if="chatPayload && chatPayload['medialType'] == 'file'"
          >
            <v-icon x-small color="grey" class="mr-1"> fa-file-alt </v-icon>
            file
          </template>

          <template
            v-else-if="chatPayload && chatPayload['medialType'] == 'video'"
          >
            <v-icon x-small color="grey" class="mr-1"> fa-video </v-icon>
            video
          </template>
          <span
            v-else-if="chatPayload"
            class="d-inline-block text-truncate"
            style="max-width: 160px"
          >
            {{ chatPayload['message'] }}
          </span>
          <v-spacer></v-spacer>
          {{ showTime() }}
        </v-row>
      </v-list-item-subtitle>
    </v-list-item-content>
  </v-container>
</template>

<script>
import { format } from 'date-fns';
import 'date-fns-tz';
import { firestore } from '../plugins/fireinit';
import ChatMixin from './ChatMixin';
import { mapState, createNamespacedHelpers } from 'vuex';
import { decrypt } from '../services/encryption_service.js';
import { db } from '../services/db';
import { liveQuery } from 'dexie';
const { mapState: chatState } = createNamespacedHelpers('chat');

export default {
  name: 'Room',
  props: {
    chatroom: Object,
  },
  mixins: [ChatMixin],
  created() {
    this.setRoom(this.chatroom);
  },
  data() {
    return {
      user: null,
      chatData: null,
      chatPayload: null,
      unreadCount: 0,
      typingUser: null,
      readReceipts: false,
      passcode: '',
      lastChatSubscription: null,
      unreadCountSubscription: null,
    };
  },
  computed: {
    ...mapState({
      colorJson: (state) => state.colorJson,
    }),
    ...chatState({
      selectedRoom: (state) => state.selectedRoom,
    }),
  },
  watch: {
    chatroom(val, oldVal) {
      if (val && oldVal && val !== oldVal) {
        this.setRoom(val);
      }
    },
  },
  unmounted() {
    // Stop subscribing:
    this.lastChatSubscription?.unsubscribe();
    this.unreadCountSubscription?.unsubscribe();
  },
  methods: {
    decryptChat(chatRoomId, data) {
      if (data && chatRoomId == data['chatRoomId']) {
        this.chatData = data;
        const keyStr = this.chatData['chatRoomId'];
        const ivStr = this.chatData['id'];
        const encrptData = this.chatData['data'];
        this.chatPayload = decrypt(keyStr, ivStr, encrptData);
        // db.chats.put(
        //   { ...this.chatData, payload: this.chatPayload },
        //   this.chatData['id']
        // );
      } else {
        this.chatData = null;
        this.chatPayload = null;
        // db.chats.delete(this.chatData['id']);
      }
    },
    async setRoom(room) {
      const myUID = sessionStorage.getItem('uid');
      this.user = this.getOtherUser(room);
      const lastChatId =
        room['lastChat'] == null
          ? room['last_chat_id']
          : room['lastChat'][myUID]['id'];
      this.typingUser =
        room['typing_user'] &&
        room['typing_user'][`${this.user['profile']['uid']}`];
      this.readReceipts = this.user['readReceipts'];
      this.passcode = room[myUID]['passcode'];
      this.getUnReadCount(room.id, lastChatId);
    },
    showTime() {
      const myUID = sessionStorage.getItem('uid');
      let timestamp;

      if (this.chatPayload) {
        timestamp = this.chatPayload.timestamp;
      } else {
        timestamp =
          this.chatroom['lastChat'] == null
            ? this.chatroom['last_chat_date']
            : this.chatroom['lastChat'][myUID]['date'];
      }

      if (!timestamp || timestamp == 0) {
        return '';
      }
      const isoDate = new Date(timestamp).toISOString();

      let date = new Date(isoDate);

      const now = new Date();
      var yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);

      if (
        date.getDate() == now.getDate() &&
        date.getMonth() == now.getMonth() &&
        date.getYear() == now.getYear()
      ) {
        return format(date, 'h:mm a');
      } else if (
        date.getYear() == yesterday.getYear() &&
        date.getMonth() == yesterday.getMonth() &&
        date.getDate() == yesterday.getDate()
      ) {
        return 'Yesterday';
      } else {
        return format(date, 'M/d/yy');
      }
    },
    getColor(alp) {
      const clr = colors(alp);
      return clr;
    },
    async getUnReadCount(chatRoomId, lastChatId) {
      // const myUID = sessionStorage.getItem('uid');
      // if (chatRoomId && myUID) {
      //   this.subscription = liveQuery(() =>
      //     // Your query goes here:
      //     db.chats.where('chatRoomId').equals(chatRoomId).toArray()
      //   ).subscribe(
      //     (list) => {
      //       // Success result:
      //       const messages = list.filter((e) => e.id == lastChatId);

      //       if (messages.length > 0) {
      //         this.decryptChat(chatRoomId, messages[0]);
      //       } else {
      //         this.decryptChat(chatRoomId, null);
      //       }

      //       if (chatRoomId == this.selectedRoom?.id) {
      //         this.unreadCount = 0;
      //       } else {
      //         const unreadChats = messages.filter((chat) => {
      //           return chat.toUID == myUID && chat.seen == false;
      //         });
      //         this.unreadCount = unreadChats?.length ?? 0;
      //       }
      //     },
      //     (error) => {
      //       // Error result:
      //       this.unreadCount = 0;
      //       this.decryptChat(chatRoomId, null);
      //     }
      //   );
      // }
      if (lastChatId) {
        // const localData = await db.chats.get(lastChatId);
        // if (lastChatId == localData?.id) {
        //   this.chatData = localData;
        //   this.decryptChat();
        // }
        // if (lastChatId != this.chatData?.id) {
        //   console.log(chatRoomId, lastChatId);
        // firestore
        //   .collection('chats')
        //   .doc(lastChatId)
        //   .onSnapshot((snapshotChat) => {
        //     this.chatData = snapshotChat.data();
        //     this.decryptChat();
        //   });
        // }
        if (this.lastChatSubscription) {
          this.lastChatSubscription?.unsubscribe();
        }
        this.lastChatSubscription = liveQuery(() =>
          // Your query goes here:
          db.chats.get(lastChatId)
        ).subscribe(
          (chat) => {
            // Success result:
            this.decryptChat(chatRoomId, chat);
          },
          (error) => {
            // Error result:
            this.decryptChat(chatRoomId, null);
          }
        );
      } else {
        this.decryptChat(chatRoomId, null);
      }

      const myUID = sessionStorage.getItem('uid');
      if (chatRoomId && myUID) {
        if (this.unreadCountSubscription) {
          this.unreadCountSubscription?.unsubscribe();
        }
        this.unreadCountSubscription = liveQuery(() =>
          db.chats
            .where(['chatRoomId', 'toUID'])
            .equals([chatRoomId, myUID])
            .and((item) => !item.seen)
            .toArray()
        ).subscribe(
          (chats) => {
            if (chatRoomId == this.selectedRoom?.id) {
              this.unreadCount = 0;
            } else {
              this.unreadCount = chats.length || 0;
            }
            // console.log(chats);
          },
          (error) => {
            // Error result:
            this.unreadCount = 0;
          }
        );
        // const query = firestore
        //   .collection('chats')
        //   .where('chatRoomId', '==', chatRoomId)
        //   .where('toUID', '==', myUID)
        //   .where('seen', '==', false)
        //   .limit(10);
        // query.onSnapshot((snapshot) => {
        //   console.log('unread', snapshot.docs.length);
        //   if (snapshot.docs.length > 0) {
        //     const unreadCounts = snapshot.docs.filter((chat) => {
        //       const data = chat.data();
        //       // console.log(data);
        //       return data.toUID == myUID;
        //     });

        //     this.unreadCount = unreadCounts != null ? unreadCounts.length : 0;
        //   } else {
        //     this.unreadCount = 0;
        //   }
        // });
      }
    },
  },
};
</script>

<style lang="scss">
.container {
  display: flex;
  align-items: center;
}
</style>
