<template>
  <v-container class="chat-container">
    <v-row v-if="currentUser">
      <v-col class="pa-0" cols="12" sm="3">
        <ChatRoomsSidebar />
      </v-col>
      <v-col class="pa-0" cols="12" sm="9">
        <ChatDetails
          v-if="selectedRoom"
          :class="{ 'mobile-chat-details': selectedRoom }"
        />
        <v-card v-else min-height="400" class="hint-card" elevation="0">
          <p>Select a chat from sidebar</p>
        </v-card>
      </v-col>
    </v-row>
    <Login v-else />
  </v-container>
</template>

<script>
import ChatDetails from './ChatDetails.vue';
import ChatRoomsSidebar from './ChatRoomsSidebar.vue';
import Login from './Login.vue';
import { mapState, createNamespacedHelpers } from 'vuex';

const { mapState: chatState } = createNamespacedHelpers('chat');

export default {
  name: 'Chats',
  components: {
    ChatDetails,
    ChatRoomsSidebar,
    Login,
  },
  created() {},
  computed: {
    ...mapState({
      currentUser: (state) => state.user,
    }),
    ...chatState({
      selectedRoom: (state) => state.selectedRoom,
    }),
  },
  methods: {},
};
</script>

<style lang="scss">
@import './Chats.scss';
</style>
