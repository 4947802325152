var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.user)?_c('v-container',{staticClass:"ma-0 pa-0"},[_c('v-list-item-avatar',[(_vm.user['profile']['imgUrl'])?_c('v-img',{attrs:{"src":_vm.user['profile']['imgUrl']}}):_c('v-avatar',{attrs:{"color":_vm.colorJson && _vm.user && _vm.user['profile'] && _vm.user['profile']['name']
          ? _vm.colorJson[
              _vm.user['profile']['name'].substring(0, 1).toUpperCase()
            ] || '#f00'
          : '#ddd',"size":"48"}},[_c('span',{staticClass:"white--text text-h5"},[_vm._v(_vm._s(_vm.user['profile']['name'].substring(0, 1)))])])],1),_c('v-list-item-content',[_c('v-list-item-title',[(_vm.user)?_c('v-row',{staticClass:"ma-0"},[_c('h4',[_vm._v(_vm._s(_vm.user['profile']['name']))]),_c('v-spacer'),(_vm.readReceipts)?_c('v-icon',{staticClass:"mr-2",attrs:{"x-small":"","color":"blue"}},[_vm._v("fa-eye")]):_c('v-icon',{staticClass:"mr-2",attrs:{"x-small":""}},[_vm._v("fa-eye-slash")]),(_vm.passcode)?_c('v-icon',{staticClass:"mr-2",attrs:{"x-small":""}},[_vm._v("fa-lock")]):_vm._e(),(_vm.unreadCount)?_c('v-avatar',{attrs:{"color":"red","size":"18"}},[_c('span',{staticClass:"white--text text-caption"},[_vm._v(" "+_vm._s(_vm.unreadCount > 9 ? '9+' : _vm.unreadCount)+" ")])]):_vm._e()],1):_vm._e()],1),_c('v-list-item-subtitle',[_c('v-row',{staticClass:"ma-0"},[(
            _vm.currentUser &&
            _vm.chatPayload &&
            _vm.currentUser.uid === _vm.chatPayload['fromUID']
          )?[(_vm.chatData['seen'] && _vm.readReceipts)?_c('v-icon',{staticClass:"mr-1",attrs:{"x-small":"","color":"blue"}},[_vm._v(" fa-check-double ")]):_c('v-icon',{staticClass:"mr-1",attrs:{"x-small":"","color":"grey"}},[_vm._v(" fa-check ")])]:_vm._e(),(_vm.typingUser && _vm.typingUser == true)?_c('div',{staticClass:"font-weight-regular",staticStyle:{"color":"green"}},[_vm._v(" typing.... ")]):(_vm.chatPayload && _vm.chatPayload['medialType'] == 'image')?[_c('v-icon',{staticClass:"mr-1",attrs:{"x-small":"","color":"grey"}},[_vm._v(" fa-image ")]),_vm._v(" image ")]:(_vm.chatPayload && _vm.chatPayload['medialType'] == 'file')?[_c('v-icon',{staticClass:"mr-1",attrs:{"x-small":"","color":"grey"}},[_vm._v(" fa-file-alt ")]),_vm._v(" file ")]:(_vm.chatPayload && _vm.chatPayload['medialType'] == 'video')?[_c('v-icon',{staticClass:"mr-1",attrs:{"x-small":"","color":"grey"}},[_vm._v(" fa-video ")]),_vm._v(" video ")]:(_vm.chatPayload)?_c('span',{staticClass:"d-inline-block text-truncate",staticStyle:{"max-width":"160px"}},[_vm._v(" "+_vm._s(_vm.chatPayload['message'])+" ")]):_vm._e(),_c('v-spacer'),_vm._v(" "+_vm._s(_vm.showTime())+" ")],2)],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }