<template>
  <div class="chat-rooms pl-2 pr-2" v-if="currentUser">
    <v-toolbar elevation="0" class="mb-2">
      <v-text-field
        class="mr-1"
        label="Search"
        solo
        hide-details
        :value="searchUser"
        @input="
          (text) => {
            setSearchUser(text);
            searchChatroomMessage(text);
          }
        "
        elevation="0"
        prepend-inner-icon="fa-search"
      ></v-text-field>

      <v-menu
        v-if="isForwardDialogShow == false"
        v-model="profileMenu"
        :close-on-content-click="false"
        :max-width="240"
        offset-y
        absolute
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon> {{ 'fa-user-circle' }}</v-icon>
          </v-btn>
        </template>

        <v-card :max-width="240">
          <v-list>
            <v-list-item>
              <v-list-item-avatar>
                <v-img
                  v-if="currentUser.imgUrl"
                  :src="currentUser.imgUrl"
                ></v-img>
                <v-avatar
                  :color="
                    colorJson && currentUser.name
                      ? colorJson[
                          currentUser.name.substring(0, 1).toUpperCase()
                        ] || '#f00'
                      : '#ddd'
                  "
                  size="48"
                  v-else-if="currentUser.name"
                >
                  <span class="white--text text-h5" :class="currentUser.name">{{
                    currentUser.name.substring(0, 1)
                  }}</span>
                </v-avatar>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title :title="currentUser.name">{{
                  currentUser.name && currentUser.name !== null
                    ? currentUser.name
                    : ''
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <v-divider></v-divider>
          <v-list>
            <template v-if="!currentUser.isSupport || false">
              <template v-if="isForwardDialogShow == false">
                <v-btn
                  v-if="!supportChat"
                  plain
                  @click="setShowSupportAddDialog(true)"
                >
                  Add Support
                </v-btn>
              </template>
              <v-divider v-if="!supportChat" class="my-1"></v-divider>
            </template>
            <v-btn plain @click="logout">
              <v-icon left>fa-logout</v-icon>
              Logout
            </v-btn>
          </v-list>
        </v-card>
      </v-menu>
    </v-toolbar>
    <v-divider />
    <v-card v-if="chatroomLoader" class="hint-card" elevation="0">
      <div>
        <v-img
          height="50"
          width="50"
          src="@/assets/images/Discuss-1s-200px.gif.gif"
        ></v-img>
      </div>
    </v-card>
    <v-list two-line>
      <template v-if="supportChat && getOtherUser(supportChat)['profile']">
        <div
          v-if="
            isForwardDialogShow &&
            forwardChatData &&
            isForwardDialogShow == true &&
            forwardChatData.chatRoomId == supportChat.id
          "
        ></div>
        <v-list-item
          v-else
          @click="onClickRoom(supportChat, false)"
          :class="{ active: currentRoom && supportChat.id === currentRoom.id }"
        >
          <Room :chatroom="supportChat"></Room>
        </v-list-item>

        <v-divider />
      </template>
      <template
        v-for="(item, index) in chatrooms.filter((item) =>
          getOtherUser(item)
            ['profile']['name'].toLowerCase()
            .includes(searchUser.toLowerCase())
        )"
      >
        <template v-if="getOtherUser(item)['profile']">
          <div
            v-if="
              isForwardDialogShow &&
              forwardChatData &&
              isForwardDialogShow == true &&
              forwardChatData.chatRoomId == item.id
            "
            :key="index"
          ></div>
          <v-list-item
            v-else
            :key="index"
            @click="onClickRoom(item, false)"
            :class="{ active: currentRoom && item.id === currentRoom.id }"
          >
            <Room :chatroom="item"></Room>
          </v-list-item>

          <v-divider :key="'div' + index" />
        </template>
      </template>
    </v-list>
    <v-list two-line v-if="searchUser">
      <div v-if="searchLoader">
        <v-img
          height="50"
          width="50"
          src="@/assets/images/Discuss-1s-200px.gif.gif"
        ></v-img>
      </div>
      <template v-for="(item, index) in results">
        <template
          v-if="item.chatroom && getOtherUser(item.chatroom)['profile']"
        >
          <div
            v-if="
              isForwardDialogShow &&
              forwardChatData &&
              isForwardDialogShow == true &&
              forwardChatData.chatRoomId == item.chatroom.id
            "
            :key="index"
          ></div>
          <v-list-item
            v-else
            :key="index"
            @click="onClickRoom(item.chatroom, true)"
          >
            <v-list-item-content>
              <v-list-item-title>
                <h4>{{ getOtherUser(item.chatroom)['profile']['name'] }}</h4>
              </v-list-item-title>
              <v-list-item-subtitle>
                <v-row class="ma-0">
                  <h4>
                    {{
                      `${
                        item.message.fromUID == currentUser.uid ? 'You: ' : ''
                      }`
                    }}
                  </h4>
                  <span
                    class="d-inline-block text-truncate"
                    style="max-width: 250px"
                    v-html="hightlight(item.message.payload.message)"
                  >
                  </span>
                  <v-spacer></v-spacer>
                  {{ showTime(item.message.date) }}
                </v-row>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider :key="'div' + index" />
        </template>
      </template>
    </v-list>
    <v-dialog v-model="showPasscodeDialog" :max-width="300">
      <v-card :max-width="300">
        <v-card-title>
          <v-col>
            <h4 class="pb-10">Please enter the passcode</h4>

            <v-otp-input
              id="pc"
              v-model="passcode"
              type="password"
              length="4"
              dark
              @finish="checkPasscode"
            ></v-otp-input>
          </v-col>
        </v-card-title>
        <v-card-actions>
          <!-- <v-btn color="red darken-1" text> Reset </v-btn>
          <v-btn color="red darken-1" text> Forget </v-btn> -->
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="showPasscodeDialog = false">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      :value="showSupportAddDialog"
      @input="setShowSupportAddDialog"
      :max-width="320"
    >
      <v-card :max-width="320">
        <v-card-title>
          Do you want to create chatroom with support Visicardia?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red darken-1"
            text
            @click="setShowSupportAddDialog(false)"
          >
            Cancel
          </v-btn>
          <v-btn color="red darken-1" text @click="addSupportChatRoom()">
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import ChatMixin from './ChatMixin';
import Room from './Room.vue';
import { auth } from '../plugins/fireinit';
import { mapMutations, mapState, createNamespacedHelpers } from 'vuex';
import { colors } from '../services/color.js';
import { format } from 'date-fns';
import { db } from '../services/db';
import { liveQuery } from 'dexie';

const {
  mapState: chatState,
  mapMutations: chatMutations,
  mapActions: chatActions,
} = createNamespacedHelpers('chat');
export default {
  name: 'ChatRoomSidebar',
  mixins: [ChatMixin],
  components: {
    Room,
  },
  props: {
    isForwardDialogShow: { type: Boolean, default: false },
    forwardChatData: { type: Object, default: null },
  },
  data() {
    return {
      showPasscodeDialog: false,
      passcode: '',
      passcodeRoom: null,
      profileMenu: false,
    };
  },
  computed: {
    ...mapState({
      colorJson: (state) => state.colorJson,
      currentUser: (state) => state.user,
    }),
    ...chatState({
      searchUser: (state) => state.searchUser,
      sessionUID: (state) => state.sessionUID,
      currentRoom: (state) => state.selectedRoom,
      supportChat: (state) => state.supportChat,
      chatrooms: (state) => state.chatrooms,
      chatroomLoader: (state) => state.chatroomLoader,
      showSupportAddDialog: (state) => state.showSupportAddDialog,
      results: (state) => state.results,
      searchLoader: (state) => state.searchLoader,
    }),
  },
  created() {
    this.getAllChatrooms();
  },
  methods: {
    ...mapMutations(['setCurrentUser']),
    ...chatMutations([
      'setCurrentRoom',
      'setMessages',
      'setSearchUser',
      'setSearchText',
      'setSearchToggle',
      'setSessionUID',
      'setSnackBartext',
      'setShowSupportAddDialog',
      'setChatroomLoader',
      'setChatrooms',
    ]),
    ...chatActions(['addSupportChatRoom', 'searchChatroomMessage']),
    async getAllChatrooms() {
      this.setChatroomLoader(true);
      try {
        const rooms = await db.chatrooms.toArray();
        this.setChatrooms(rooms);
        this.setChatroomLoader(false);
      } catch (error) {
        this.setChatrooms([]);
        this.setChatroomLoader(false);
      }
    },
    async logout() {
      this.setCurrentRoom(null);
      await auth.signOut();
      sessionStorage.removeItem('uid');
      this.setSessionUID(null);
      this.setCurrentUser(null);

      db.chatrooms.clear();
      db.chats.clear();
    },
    async onClickRoom(item, setFindChat) {
      if (
        this.isForwardDialogShow &&
        this.forwardChatData &&
        this.isForwardDialogShow == true
      ) {
        const resChat = await this.forwardMsgTo(
          item,
          this.forwardChatData['payload']
        );
        this.$emit('close-dialog');
        if (resChat != null) {
          this.setSnackBartext('Message forwarded!');
        }
      } else {
        // console.log(item['id']);
        const myUID = sessionStorage.getItem('uid');
        if (item[myUID]['passcode']) {
          this.passcodeRoom = item;
          this.showPasscodeDialog = true;
        } else {
          if (setFindChat) {
            this.setSearchText(this.searchUser);
            this.setSearchToggle(true);
          } else {
            this.setSearchText('');
            this.setSearchToggle(false);
          }
          this.setCurrentRoom(item);
        }
      }
    },
    hightlight(text) {
      if (this.searchUser) {
        const reg = new RegExp(this.searchUser, 'gi');

        const str = text.replace(
          reg,
          '<b style="background-color: yellow" class="find-text">' +
            this.searchUser +
            '</b>'
        );
        return str;
      }
      return text;
    },
    showTime(timestamp) {
      if (!timestamp || timestamp == 0) {
        return '';
      }
      const isoDate = new Date(timestamp).toISOString();

      let date = new Date(isoDate);

      const now = new Date();
      var yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);

      if (
        date.getDate() == now.getDate() &&
        date.getMonth() == now.getMonth() &&
        date.getYear() == now.getYear()
      ) {
        return format(date, 'h:mm a');
      } else if (
        date.getYear() == yesterday.getYear() &&
        date.getMonth() == yesterday.getMonth() &&
        date.getDate() == yesterday.getDate()
      ) {
        return 'Yesterday';
      } else {
        return format(date, 'M/d/yy');
      }
    },
    getColor(alp) {
      const clr = colors(alp);
      return clr;
    },
    checkPasscode() {
      if (
        this.passcodeRoom &&
        this.passcode == this.passcodeRoom[this.sessionUID]['passcode']
      ) {
        this.setCurrentRoom(this.passcodeRoom);
        this.passcode = '';
        this.showPasscodeDialog = false;
        this.passcodeRoom = null;
      } else {
        if (this.passcode !== this.passcodeRoom[this.sessionUID]['passcode']) {
          this.setSnackBartext('Wrong Password');
        }
        this.passcode = '';
      }
    },
  },
};
</script>
