import { mapActions, mapState } from 'vuex';
import { firestore, storage, firebase } from '../plugins/fireinit';
import {
  encrypt,
  fileExtensions,
  imageExtensions,
} from '../services/encryption_service.js';
import { format, differenceInHours, formatDistance } from 'date-fns';
import Graphemer from 'graphemer';

export default {
  data: () => ({}),
  computed: {
    ...mapState({
      currentUser: (state) => state.user,
    }),
    foundTextList() {
      if (this.findChatText) {
        const texts = document.querySelectorAll('.find-text');
        return texts;
      }
      return [];
    },
  },
  methods: {
    ...mapActions(['fetchCurrentUser']),
    getOtherUser(item) {
      let user = {};
      item['uids'].forEach((u) => {
        if (u != this.currentUser?.uid) {
          user = item[u];
        }
      });
      return user;
    },
    getTimeAgo(timestamp) {
      const chatTime = new Date(timestamp);
      var timeStr = format(chatTime, 'dd MMM HH:mm');
      const now = new Date();
      if (differenceInHours(now, chatTime) < 24) {
        timeStr = formatDistance(chatTime, now, { addSuffix: true });
      }
      return timeStr;
    },
    isUrl(str) {
      const strArr = str.split(' ');
      // const urlRegex = new RegExp(
      //   /(?:(?:https?|ftp):\/\/)?[\w/\-?=%]+\.[\w/\-&?=%]+/
      // );
      const urlRegex = new RegExp(
        /(?:(?:(?:ftp|http)[s]*:\/\/|www\.)[^\.]+\.[^ \n]+)/
      );
      const emojiRegex = new RegExp(
        /[\u{1f300}-\u{1f5ff}\u{1f900}-\u{1f9ff}\u{1f600}-\u{1f64f}\u{1f680}-\u{1f6ff}\u{2600}-\u{26ff}\u{2700}-\u{27bf}\u{1f1e6}-\u{1f1ff}\u{1f191}-\u{1f251}\u{1f004}\u{1f0cf}\u{1f170}-\u{1f171}\u{1f17e}-\u{1f17f}\u{1f18e}\u{3030}\u{2b50}\u{2b55}\u{2934}-\u{2935}\u{2b05}-\u{2b07}\u{2b1b}-\u{2b1c}\u{3297}\u{3299}\u{303d}\u{00a9}\u{00ae}\u{2122}\u{23f3}\u{24c2}\u{23e9}-\u{23ef}\u{25b6}\u{23f8}-\u{23fa}]/gu
      );
      const emailRegex = new RegExp(
        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/
      );
      let list = [];
      strArr.map((s) => {
        if (s.match(emojiRegex)) {
          const splitter = new Graphemer();
          const graphemes = splitter.splitGraphemes(s);
          // console.log(graphemes);
          let word = '';
          graphemes.forEach((emj) => {
            // console.log(emj);
            if (emj.match(emojiRegex)) {
              // console.log('word', word);
              list.push({
                email: false,
                emoji: false,
                url: false,
                text: word,
              });
              list.push({
                email: false,
                emoji: true,
                url: false,
                text: emj,
              });
              word = '';
            } else {
              word += emj;
            }
          });
          if (word) {
            list.push({
              email: false,
              emoji: false,
              url: false,
              text: word,
            });
          }
        } else if (s.match(emailRegex)) {
          list.push({
            email: true,
            emoji: false,
            url: true,
            text: s + ' ',
          });
        } else if (s.match(urlRegex)) {
          list.push({
            email: false,
            emoji: false,
            url: true,
            text: s + ' ',
          });
        } else {
          list.push({
            email: false,
            emoji: false,
            url: false,
            text: s + ' ',
          });
        }
      });
      return list;
      // let matches;
      // let emojis;
      // if (strArr.length > 0) {
      //   if (strArr.length === 1) {
      //     emojis = str.match(new RegExp(emojiRegex));
      //     if (emojis) {
      //       return {
      //         emoji: true,
      //         url: false,
      //         text: str,
      //       };
      //     }
      //     matches = str.match(new RegExp(urlRegex));
      //     if (matches) {
      //       return {
      //         emoji: false,
      //         url: true,
      //         text: str,
      //       };
      //     }
      //   } else {
      //     return strArr.map((s) => {
      //       emojis = s.match(new RegExp(emojiRegex));
      //       if (emojis) {
      //         return {
      //           emoji: true,
      //           url: false,
      //           text: s,
      //         };
      //       }
      //       const mat = s.match(new RegExp(urlRegex));
      //       if (mat) {
      //         return {
      //           emoji: false,
      //           url: true,
      //           text: mat[0],
      //         };
      //       }
      //       return { emoji: false, url: false, text: s };
      //     });
      //   }
      // }
      // return {
      //   emoji: false,
      //   url: false,
      //   text: str,
      // };
    },
    getMediaName(url) {
      if (url == null || url == '') {
        return '';
      }
      var httpsReference = storage.refFromURL(url);
      // console.log(httpsReference);
      return httpsReference.name;
    },
    getMediaExtension(url) {
      if (url == null || url == '') {
        return '';
      }
      let fileName = this.getMediaName(url);
      // console.log(fileName);
      return fileName.split('.')[1];
    },
    getMediaType(url) {
      if (url == null || url == '') {
        return 'file';
      }
      let ext = this.getMediaExtension(url);
      // console.log(ext);
      var fe = fileExtensions;
      var ie = imageExtensions;
      if (fe.indexOf(ext?.toLowerCase()) > -1) {
        return 'file';
      } else if (ie.indexOf(ext?.toLowerCase()) > -1) {
        return 'image';
      } else {
        return 'video';
      }
    },
    async forwardMsgTo(chatRoom, chatData) {
      // console.log(chatRoom);

      const currentUID = sessionStorage.getItem('uid');
      const chatRoomId = chatRoom['id'];
      const timestamp = new Date().getTime();
      const profile = this.getOtherUser(chatRoom)['profile'];

      if (chatRoomId != null) {
        var _ref = firestore.collection('chats').doc();

        if (chatData.medialUrl != null && chatData.medialUrl != '') {
          const ext = this.getMediaExtension(chatData.medialUrl);
          const folder = `chat/${chatData.fromUID}`;
          const fileName = `chat-${chatData.fromUID}-${chatData.id}.${ext}`;

          const newfileName = `chat-${chatData.fromUID}-${_ref.id}.${ext}`;

          this.copyAndPasteFile(folder, fileName, folder, newfileName);

          console.log('old: ', chatData.medialUrl);

          const url = chatData.medialUrl.replaceAll(
            `${chatData.id}.${ext}`,
            `${_ref.id}.${ext}`
          );

          console.log('new: ', url);

          if (url) {
            chatData.medialUrl = url;
          }
        }

        chatData.id = _ref.id;
        chatData.toUID = profile['uid'];
        chatData.users = [currentUID, profile['uid']];
        chatData.chatRoomId = chatRoomId;
        chatData.timestamp = timestamp;
        if (currentUID != chatData.fromUID) {
          chatData.fromUID = currentUID;
          chatData.isForwarded = true;
        }
        // console.log(chatData, chatRoomId);
        const resChat = await this.storeMessage(
          chatData,
          chatRoomId,
          timestamp
        );
        if (resChat != null) {
          console.log(resChat);
          return resChat;
        } else {
          console.log('Something went wrong,');
        }
      } else {
        console.log('Something went wrong');
      }
    },
    async copyAndPasteFile(fromBucket, fromFileName, toBucket, toFileName) {
      const copyFilesInFolder = firebase
        .functions()
        .httpsCallable('copyFilesInFolder');
      const map = {
        fromFolder: `${fromBucket}/${fromFileName}`,
        toFolder: `${toBucket}/${toFileName}`,
      };
      const result = await copyFilesInFolder(map);
      return result.data;
    },
    async sendMessage(
      medialType,
      msg,
      room,
      fromUID,
      toUID,
      replyMessage,
      ext
    ) {
      if (medialType == '' && msg.trim().length === 0) {
        console.log('type message ');
        return;
      }

      const timestamp = new Date().getTime();

      // console.log(room);

      const chatRoomId = room['id'];

      console.log('fromUID', fromUID);
      console.log('toUID', toUID);

      const data = {
        id: null,
        users: [fromUID, toUID],
        medialUrl: '',
        medialType: medialType ?? '',
        timestamp: timestamp,
        cardId: room['cardId'] ?? null,
        message: medialType == '' ? msg.trim() : '',
        toUID: toUID,
        fromUID: fromUID,
        recdCardId: chatRoomId,
        repliedTo: null,
        repliedToChatId: null,
        repliedToChatContent: null,
        isForwarded: null,
        ext: ext,
      };
      this.textMessage = '';

      if (replyMessage != null) {
        const replyingToChat = replyMessage['payload'];
        const toUserData = this.getOtherUser(room)['profile'];
        data.repliedTo =
          replyingToChat.fromUID == fromUID
            ? 'You'
            : toUserData.name.toUpperCase();
        data.repliedToChatId = replyingToChat.id;
        data.repliedToChatContent =
          replyingToChat.medialUrl != null && replyingToChat.medialUrl != ''
            ? replyingToChat.medialUrl
            : replyingToChat.message;
      }

      // console.log(replyMessage);
      // console.log(data);
      this.closeReply();
      return await this.storeMessage(data, chatRoomId, timestamp);
    },
    async storeMessage(chatData, chatRoomId, timestamp) {
      console.log('storeMessage');
      try {
        return new Promise(async (resolve) => {
          var ref;
          if (chatData['id']) {
            ref = firestore.collection('chats').doc(chatData['id']);
          } else {
            ref = firestore.collection('chats').doc();
          }

          const documentId = ref.id;
          chatData['id'] = documentId;

          const encryptedData = encrypt(chatRoomId, documentId, chatData);

          // console.log(chatData);

          await ref.set({
            data: encryptedData,
            id: documentId,
            chatRoomId,
            fromUID: chatData.fromUID,
            toUID: chatData.toUID,
            seen: false,
            date: timestamp,
            deletedFor: {
              [chatData.toUID]: false,
              [chatData.fromUID]: false,
            },
          });

          // console.log(`chatRoomId: ${chatRoomId}`);
          await firestore
            .collection('chatrooms')
            .doc(chatRoomId)
            .update({
              last_chat_id: documentId,
              last_chat_date: timestamp,
              lastChat: {
                [`${chatData.toUID}`]: { id: documentId, date: timestamp },
                [`${chatData.fromUID}`]: { id: documentId, date: timestamp },
              },
            });

          resolve(chatData);
        });
      } catch (e) {
        console.log('send message: $e');
        return null;
      }
    },
    async updateMessage(chatId, chatData) {
      console.log('updateMessage');
      try {
        return new Promise(async (resolve) => {
          const ref = firestore.collection('chats').doc(chatId);
          const documentId = ref.id;
          const chatRoomId = chatData.recdCardId;

          const encryptedData = encrypt(chatRoomId, documentId, chatData);

          // console.log(chatData);

          await ref.update({
            data: encryptedData,
          });

          resolve(true);
        });
      } catch (e) {
        console.log('send message: $e');
        return null;
      }
    },
  },
};
