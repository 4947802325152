<template>
  <div
    v-if="message['payload']"
    :class="{ mine: currentUser.uid === message['fromUID'] }"
    class="message"
  >
    <v-col>
      <template v-if="message['payload']['isForwarded']">
        <v-icon x-small color="grey">fa-long-arrow-alt-right</v-icon>
        <span class="text-caption text--disabled ml-1">Forwarded</span>
      </template>
      <div v-if="message['payload']['repliedToChatId']" class="reply-container">
        <div v-if="message['payload']['repliedTo']">
          {{ message['payload']['repliedTo'] }}
        </div>
        <div v-if="message['payload']['repliedToChatContent']">
          <div
            v-if="
              message['payload']['repliedToChatContent'].startsWith('http') &&
              message['payload']['repliedToChatContent'].includes(
                'firebasestorage.googleapis.com'
              )
            "
          >
            <a
              target="_blank"
              download
              :href="message['payload']['repliedToChatContent']"
              style="word-break: break-all"
            >
              <template
                v-if="message['payload']['repliedToChatMedialType'] == 'image'"
              >
                <v-icon x-small color="grey" class="mr-1"> fa-image </v-icon>
                Photo
              </template>

              <template
                v-else-if="
                  message['payload']['repliedToChatMedialType'] == 'file'
                "
              >
                <v-icon x-small color="grey" class="mr-1"> fa-file-alt </v-icon>
                File
              </template>

              <template v-else>
                <v-icon x-small color="grey" class="mr-1"> fa-video </v-icon>
                Video
              </template>

              <v-img
                :src="message['payload']['repliedToChatContent']"
                height="50"
                width="50"
                v-if="message['payload']['repliedToChatMedialType'] == 'image'"
              >
              </v-img>
            </a>
          </div>
          <div v-else>
            {{ message['payload']['repliedToChatContent'] }}
          </div>
        </div>
      </div>
      <div v-if="message['payload']['medialType'] == 'image'">
        <v-row
          v-if="message['payload']['error']"
          class="fill-height ma-0"
          align="center"
          justify="center"
        >
          <v-icon x-small color="grey">fa-exclamation</v-icon>
          <h5 style="color: grey; margin: 5px">this file might be deleted</h5>
        </v-row>
        <v-img
          v-else
          :src="mediaUrl"
          height="150"
          width="150"
          @click="$emit('openFilePreview', message)"
          @error="onError"
        >
          <template v-slot:placeholder v-if="!mediaUrl">
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular
                indeterminate
                color="grey"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </div>
      <div
        style="height: 150px; width: 150px; background-color: black"
        v-else-if="message['payload']['medialType'] == 'video'"
        @click="$emit('openFilePreview', message)"
      >
        <v-row
          v-if="!mediaUrl"
          class="fill-height ma-0"
          align="center"
          justify="center"
        >
          <v-progress-circular indeterminate color="grey"></v-progress-circular>
        </v-row>
        <v-row v-else class="fill-height ma-0" align="center" justify="center">
          <v-icon color="grey" class="justify-center"> fa-play </v-icon>
        </v-row>
      </div>
      <div v-else-if="message['payload']['medialType'] == 'file'">
        <v-row
          v-if="!mediaUrl"
          class="fill-height ma-0"
          align="center"
          justify="center"
        >
          <v-progress-circular indeterminate color="grey"></v-progress-circular>
        </v-row>
        <a
          v-else
          :href="mediaUrl"
          download
          target="_blank"
          style="word-break: break-all"
        >
          {{ getMediaExtension(mediaUrl).toUpperCase() }}
          File
        </a>
      </div>
      <div v-else-if="message['payload']['medialType'] == ''">
        <template
          v-if="
            isUrl(message['payload']['message']) &&
            isUrl(message['payload']['message']).length &&
            typeof isUrl(message['payload']['message']) === 'object'
          "
        >
          <template v-for="(item, ind) in isUrl(message['payload']['message'])">
            <span id="emoji" v-if="item.emoji" :key="ind" class="text-h4"
              >{{ item.text }}
            </span>
            <a
              :href="
                item.text.startsWith('https://') ||
                item.text.startsWith('http://')
                  ? item.text
                  : item.email
                  ? 'mailto:' + item.text + '?subject=&body='
                  : 'https://' + item.text
              "
              :key="ind"
              v-else-if="item.url"
              target="_blank"
              v-html="hightlight(item.text)"
              style="word-break: break-all"
            />
            <span v-else :key="ind" v-html="hightlight(item.text)" />
          </template>
        </template>
        <!-- <template v-if="isUrl(message['payload']['message'])">
          <span
            id="emoji"
            v-if="isUrl(message['payload']['message']).emoji"
            class="text-h3"
            >{{ isUrl(message['payload']['message']).text }}
          </span>
          <a
            :href="'http://' + isUrl(message['payload']['message']).text"
            v-else-if="isUrl(message['payload']['message']).url"
            target="_blank"
            >{{ isUrl(message['payload']['message']).text }}</a
          >
          <span v-else>{{ isUrl(message['payload']['message']).text }} </span>
        </template> -->
      </div>

      <div
        class="text-subtitle-2"
        :class="`text-${
          currentUser.uid === message['fromUID'] ? 'right' : 'left'
        }`"
      >
        {{ getTimeAgo(message['payload']['timestamp']) }}
        <template v-if="currentUser.uid === message['fromUID']">
          <v-icon v-if="message['seen'] && readReceipts" x-small color="blue">
            fa-check-double
          </v-icon>
          <v-icon v-else x-small color="grey"> fa-check </v-icon>
        </template>
        <v-menu offset-y>
          <template v-slot:activator="{ on }">
            <v-icon small v-on="on" class="ml-1">fa-chevron-down </v-icon>
          </template>
          <v-list>
            <v-list-item
              v-if="message['payload']['medialType'] == ''"
              @click="$emit('copyClick', message['payload']['message'])"
            >
              <v-list-item-icon class="mr-1">
                <v-icon small>fa-copy</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>copy</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-else-if="mediaUrl" @click="download(mediaUrl)">
              <v-list-item-icon class="mr-1">
                <v-icon small>fa-download</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  download
                  <!-- <a
                    :href="mediaUrl"
                    download
                    target="_blank"
                  >
                    download
                  </a> -->
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item @click="$emit('forwardClick', message)">
              <v-list-item-icon class="mr-1">
                <v-icon small>fa-long-arrow-alt-right</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>forward</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item @click="$emit('replyClick', message)">
              <v-list-item-icon class="mr-1">
                <v-icon small>fa-reply</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>reply</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item @click="$emit('deleteClick', message, isLastIndex)">
              <v-list-item-icon class="mr-1">
                <v-icon small>fa-trash</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>delete</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </v-col>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import ChatMixin from './ChatMixin';
const { mapState } = createNamespacedHelpers('chat');
import { storage } from '../plugins/fireinit';

export default {
  name: 'Chat',
  props: ['message', 'readReceipts', 'isLastIndex'],
  mixins: [ChatMixin],
  data() {
    return {
      mediaUrl: null,
    };
  },
  created() {
    this.findUrl();
  },
  watch: {
    searchText(val, oldVal) {
      if (val && oldVal && val !== oldVal) {
        console.log('searchText', val);
      }
    },
    message(val, oldVal) {
      if (val && oldVal && val !== oldVal) {
        this.findUrl();
      }
    },
  },
  computed: {
    ...mapState({
      findChatText: (state) => state.findChatText,
    }),
  },
  methods: {
    onError(e) {
      this.message['payload']['error'] = true;
      this.$forceUpdate();
    },
    download(url) {
      const options = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      };

      const name = this.getMediaName(url);

      fetch(url, options).then((response) => {
        response.blob().then((blob) => {
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement('a');
          a.href = url;
          console.log(name);
          a.download = name;
          a.click();
        });
      });
    },
    async findUrl() {
      const medialUrl = this.message['payload']['medialUrl'];
      if (medialUrl) {
        const ext = this.getMediaExtension(medialUrl);
        const folder = `chat/${this.message['payload']['fromUID']}`;
        const fileName = `chat-${this.message['payload']['fromUID']}-${this.message.id}.${ext}`;
        try {
          this.mediaUrl = await storage
            .ref(`${folder}/${fileName}`)
            .getDownloadURL();
        } catch (e) {
          // console.log(e);
          this.mediaUrl = medialUrl;
        }
      }
    },
    hightlight(text) {
      if (this.findChatText) {
        const reg = new RegExp(this.findChatText, 'gi');

        const str = text.replace(
          reg,
          '<b style="background-color: yellow" class="find-text">' +
            this.findChatText +
            '</b>'
        );
        return str;
      }
      return text;
    },
  },
};
</script>
